/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { IFilterCategory } from 'src/apis/types/filterListAPI';
import { getFetch } from 'src/utils/fetcher';
import filterSearchProjectFullResult from 'src/mocks/filter/filterSearchProjectFullResult';
import { toCamel } from 'src/utils/object';

const FILTER_URL_PATH = (pageIdentifier: string) => `/api/v2/filters/list/${pageIdentifier}`;
export const FILTER_MSW_STATUS_KEY = 'filterAPI';

export const FILTER_KEY = 'FILTER';

export const useGetFilterAPI = (pageIdentifier: string) => {
  const filterFetch = async (): Promise<IFilterCategory> => {
    // For mock purpose, To be remove
    try {
      const response = await getFetch({
        path: FILTER_URL_PATH(pageIdentifier),
        key: FILTER_MSW_STATUS_KEY,
      });
      return response;
    } catch (error) {
      const data = filterSearchProjectFullResult as IFilterCategory;
      return toCamel(data);
    }
  };

  const { data, ...restProps } = useQuery([`${FILTER_KEY}_${pageIdentifier}`], filterFetch, {
    enabled: !!pageIdentifier,
  });

  return {
    filterList:
      data?.entities?.map(({ properties = {} }) => ({
        ...properties,
      })) || [],
    ...restProps,
  };
};
