import { ReactNode, useRef } from 'react';
import { ISavedView } from 'src/apis/savedViewAPI';
import { IFilterCategoryProperties } from 'src/apis/types/filterListAPI';
import { SidePanel } from 'src/components/layout/SidePanel';
import { initFilterStore } from 'src/reducers/FilterReducer';
import { IFilterProps, IMenuItem, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { TViewOptions } from 'src/screens/ResourcePlanner/types/resourcePlanner';
import { FilterStoreProvider } from 'src/stores/FilterStore';
import { TSavedViewAction } from 'src/apis/savedViewAPI/savedViewAPI';
import { MainContainer } from './components/MainContainer';
import { FilterList } from './components/SideBar';
import { IViewOptionsField, ViewOptionsChangeParameters } from './types';

interface IFilterLayout {
  filterList?: Array<Partial<IFilterCategoryProperties>>;
  selectedFilterList?: Record<TFilterID, IFilterProps>;
  children: ReactNode;
  viewOptionsLeftFilter?: ReactNode;
  viewOptionsFields?: Array<IViewOptionsField>;
  viewOptionsChange?: (item: ViewOptionsChangeParameters[]) => void;
  localStorageNamePrefix: string;
  allowSavedViews?: boolean;
  savedViewsList?: ISavedView[];
  activeView?: string;
  menuItems?: IMenuItem[];
  handleViewAction?: (action: TSavedViewAction) => void;
  setActiveView?: (view?: string) => void;
  changedViewOptions?: TViewOptions;
  allowSearch?: boolean;
}

export const FilterLayout = ({
  filterList = [],
  selectedFilterList = {},
  children,
  viewOptionsLeftFilter,
  viewOptionsFields,
  viewOptionsChange,
  localStorageNamePrefix,
  allowSavedViews = false,
  savedViewsList = [],
  activeView = '',
  menuItems = [],
  handleViewAction = () => {},
  setActiveView = () => {},
  changedViewOptions,
  allowSearch,
}: IFilterLayout) => {
  const activateSidePanelButtonRef = useRef<HTMLButtonElement>(null);
  return (
    <FilterStoreProvider
      value={{
        ...initFilterStore,
        filterList,
        selectedFilterList,
        filterQueryObj: selectedFilterList,
      }}
    >
      <SidePanel
        sideBar={<FilterList activateSidePanelButtonRef={activateSidePanelButtonRef} />}
        activateSidePanelButtonRef={activateSidePanelButtonRef}
      >
        <MainContainer
          viewOptionsLeftFilter={viewOptionsLeftFilter}
          viewOptionsFields={viewOptionsFields}
          viewOptionsChange={viewOptionsChange}
          activateSidePanelButtonRef={activateSidePanelButtonRef}
          localStorageNamePrefix={localStorageNamePrefix}
          allowSavedViews={allowSavedViews}
          savedViewsList={savedViewsList}
          activeView={activeView}
          menuItems={menuItems}
          handleViewAction={handleViewAction}
          setActiveView={setActiveView}
          changedViewOptions={changedViewOptions}
          allowSearch={allowSearch}
        >
          {children}
        </MainContainer>
      </SidePanel>
    </FilterStoreProvider>
  );
};
