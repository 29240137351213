import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SystemAdministration from 'src/screens/SystemAdministration';
import PageSettings from 'src/screens/PageSettings';
import Home from 'src/screens/Home';
import PersonalSettings from 'src/screens/PersonalSettings';
import LayoutPage from 'src/components/layout/LayoutPage';
import LegacyPage from 'src/components/layout/LegacyPage';
import { APP_URL } from 'src/constants/cookies';
import TimeTrackerTestComponent from 'src/utils/time/timeTrackerTime/TimeTrackerTestComponent';
import PageRoute from 'src/routers/PageRoute';
import LegacyRoute from 'src/routers/LegacyRoute';
import SamplePathRoute from 'src/routers/SamplePathRoute';
import { isDev } from 'src/utils/env';
import {
  systemAdministrationRoutes,
  personalSettingsRoutes,
  designSixPageSettingsRoutes,
  editProjectTemplateRoute,
} from 'src/constants/paths';
import Cookies from 'js-cookie';
import { designSevenPagePath } from 'src/designSevenPagePath';
import AuthRoute from '../AuthRoute';
import ProjectsRouter from '../ProjectsRouter';
import { ReportsRouter } from '../ReportsRouter';
import PageSettingsRouter from '../PageSettingsRouter';

export const administrativeRoutes = systemAdministrationRoutes
  .concat(personalSettingsRoutes)
  .concat(designSixPageSettingsRoutes);

const LogOff = () => {
  window.location.href = `${Cookies.get(APP_URL)}/authentication/logoff`;
  return null;
};

export const MainRouter = () => {
  const { pathname } = useLocation();

  return (
    <Routes>
      {personalSettingsRoutes?.map((url) => (
        <Route
          key={url}
          path={url}
          element={
            <AuthRoute>
              <PersonalSettings />
            </AuthRoute>
          }
        />
      ))}

      {/* Must be put before system administration route for edit project template */}
      <Route
        path={editProjectTemplateRoute}
        element={
          <AuthRoute>
            <LayoutPage />
          </AuthRoute>
        }
      >
        <Route path="*" element={<LegacyPage data-automation-id="LegacyPage" />} />
      </Route>

      {systemAdministrationRoutes?.map((url) => (
        <Route
          key={url}
          path={url}
          element={
            <AuthRoute>
              <SystemAdministration />
            </AuthRoute>
          }
        />
      ))}

      {designSixPageSettingsRoutes?.map((url) => (
        <Route
          key={url}
          path={url}
          element={
            <AuthRoute>
              <PageSettings />
            </AuthRoute>
          }
        />
      ))}

      <Route path={designSevenPagePath.pageSettings.root} element={<PageSettingsRouter />} />

      {isDev && (
        <Route path="/test/utils/time/timeTrackerTime" element={<TimeTrackerTestComponent />} />
      )}

      {isDev && <Route path={designSevenPagePath.sample.root} element={<SamplePathRoute />} />}

      {/* Route for log out */}
      <Route path="/logoff" element={<LogOff />} />

      <Route
        path="/"
        element={
          <AuthRoute>
            {/*
              We need display: inline-block for the table to overflow horizontally.
              Once RP is migrated to use MUI, we can remove this, as it will not overflow.
            */}
            <LayoutPage hasCustomTable={pathname === '/projects/resource-planner'} />
          </AuthRoute>
        }
      >
        <Route
          path={designSevenPagePath.home}
          element={
            <PageRoute
              pageTitle="Home"
              pageId="5f11ec8e-27f0-47b2-9bb2-667ed9f4800c"
              pageIdentifier="DesignSevenFrontPage"
            >
              <Home />
            </PageRoute>
          }
        />

        <Route path={designSevenPagePath.reports.root} element={<ReportsRouter />} />

        <Route path={designSevenPagePath.projects.root} element={<ProjectsRouter />} />

        <Route
          path={designSevenPagePath.employees.root}
          element={
            <Routes>
              <Route
                path={designSevenPagePath.employees.resourcePlannerOldUrl}
                element={
                  <Navigate
                    to={`${designSevenPagePath.projects.root.replace('*', '')}${
                      designSevenPagePath.projects.resourcePlanner
                    }`}
                    replace
                  />
                }
              />
            </Routes>
          }
        />

        {/* Feature off for new Search Projects, will be enabled in the future */}
        <Route
          path={designSevenPagePath.projectManagement.root}
          element={
            <Routes>
              <Route
                path={designSevenPagePath.projectManagement.searchProjectsOldUrl}
                element={
                  <Navigate
                    to={`${designSevenPagePath.projects.root.replace('*', '')}${
                      designSevenPagePath.projects.searchProjects
                    }`}
                    replace
                  />
                }
              />
            </Routes>
          }
        />

        <Route
          path="*"
          element={
            <LegacyRoute>
              <LegacyPage data-automation-id="LegacyPage" />
            </LegacyRoute>
          }
        />
      </Route>
    </Routes>
  );
};
